import React from "react";
import { MovingWebsite } from "../components/UnderConstruction";

export function About() {

    return (
      <>
        <div className="row">
         <MovingWebsite/>
        </div>
        
      </>
    );
  }
  