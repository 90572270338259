import React from "react";
import { MovingWebsite } from "../../components/UnderConstruction";
import { InstaEmbedRow } from "../../components/Embeds/components/embeds/EmbedRow";

export function artother() {
return ( 
 <>
<div className="row">
<div className="col"><MovingWebsite/>
</div>
</div>
<InstaEmbedRow
    url1="https://www.instagram.com/p/CaAqC_gqAnV"
    url2="https://www.instagram.com/p/C-LBWZNOwMg"
    url3="https://www.instagram.com/p/"
/>
</>
);
}
