import React from "react";
import { MovingWebsite } from "../../components/UnderConstruction";

export function Morphsmorphs() {
return ( 
 <>
<div className="row">
<div className="col"><MovingWebsite/>
</div>
</div>
<div className="row"></div>
</>
);
}
