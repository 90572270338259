import React from "react";

export function Footer() {


  return (
    <footer className="pt-5 pb-5 footer py-5 mt-5 bg-d text-white">

    </footer>
  );
}
