import React from "react";
import { MovingWebsite } from "../../components/UnderConstruction";
import { InstaEmbedRow } from "../../components/Embeds/components/embeds/EmbedRow";

export function artprints() {
return ( 
 <>
<div className="row">
<div className="col"><MovingWebsite/>
</div>
</div>
<InstaEmbedRow
    url1="https://www.instagram.com/p/CnZ-QTtMpMH"
    url2="https://www.instagram.com/p/Chm-SSJLHFP"
    url3="https://www.instagram.com/p/ChC-qUUNt1V"
/>



</>
);
}
